import _ from 'lodash';
import React, { useState } from 'react';
import axios from 'axios';

import { Grid, Image } from '@jvs-group/jvs-mairistem-composants';
import {
  SemanticToastContainer,
  toast,
} from 'react-semantic-toasts';

import FormulaireNouveau from './FormulaireNouveau';
import Authentification from './Authentification';
import RGPD from './RGPD';

import logo from '../assets/images/logo.svg';
import Background from './Background';

const url = '/api';

/**
 * Application.
 */
const Application = () => {
  const [authentified, setAuthentified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [numeroClient, setNumeroClient] = useState('');
  const [messageError, setMessageError] = useState(null);
  const [codePostal, setCodePostal] = useState('');
  const [rgpd, setRgpd] = useState(false);
  const [token, setToken] = React.useState();

  const handleAuthentification = (idClient, cp) => {
    setLoading(true);
    setNumeroClient(idClient);
    setCodePostal(cp);

    axios.post(url, {
      numeroClient: idClient,
      codePostal: cp,
    }).then((response) => {
      setLoading(false);
      if (response.status && response.status === 200) {
        setAuthentified(true);

        setToken(response.data.token);

        toast({
          title: 'Authentification réussie',
          description: '',
          type: 'success',
          time: 500000,
        });
      }
    }).catch((e) => {
      setLoading(false);

      const message = !_.isNil(e)
            && !_.isNil(e.response)
            && !_.isNil(e.response.data)
            && !_.isNil(e.response.data.message)
        ? e.response.data.message
        : 'Authentification échoué';

      toast({
        title: 'Authentification échouée',
        description: <p>{message}</p>,
        type: 'error',
        time: 5000,
      });
    });
  };

  const handleSubmitFormulaireNew = (
    nom,
    prenom,
    nomAncien,
    prenomAncien,
    nomBeneficiaire,
    prenomBeneficiaire,
    nomAncienTeleservices,
    prenomAncienTeleservices,
    mail,
    mailBeneficiaire,
    siret,
    adresseCollectivite,
    adresseBureauPoste,
    adresseTeleservices,
    telephone,
    telephoneBeneficiaire,
    telephoneRepresentant,
  ) => {
    setLoading(true);

    axios.post(`${url}/certificat`, {
      nom,
      prenom,
      nomAncien,
      prenomAncien,
      nomBeneficiaire,
      prenomBeneficiaire,
      nomAncienTeleservices,
      prenomAncienTeleservices,
      mail,
      mailBeneficiaire,
      siret,
      adresseCollectivite,
      adresseBureauPoste,
      adresseTeleservices,
      numeroClient,
      codePostal,
      telephone,
      telephoneBeneficiaire,
      telephoneRepresentant,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      setMessageError(null);
      setLoading(false);
      setAuthentified(false);

      if (response.status && response.status === 200) {
        setAuthentified(false);

        toast({
          title: "L'envoi du formulaire a réussi",
          description: '',
          type: 'success',
          time: 0,
        });
      }
    }).catch((e) => {
      setLoading(false);

      const message = !_.isNil(e)
            && !_.isNil(e.response)
            && !_.isNil(e.response.data)
            && !_.isNil(e.response.data.message)
        ? e.response.data.message
        : "L'envoi du formulaire a échoué.";

      setMessageError(message);
    });
  };

  const handleBack = () => setAuthentified(false);

  const handleAccept = () => setRgpd(true);

  const handleRefuse = () => { window.location.href = 'https://jvs-mairistem.fr'; };

  return (
    <>
      <SemanticToastContainer />
      {!rgpd && <RGPD onAccept={handleAccept} onRefuse={handleRefuse} /> }
      <Background />
      <Grid
        style={{
          minHeight: '100%',
          display: 'flex',
          flexDirection: 'column',
          margin: 0,
          flex: 1,
        }}
      >
        <Grid.Row centered style={{ padding: 0 }}>
          <Image
            src={logo}
            style={{ width: 300 }}
            size="large"
            fluid
          />
        </Grid.Row>
        <Grid.Row
          stretched
          centered
          style={{
            flex: 1,
            padding: 0,
          }}
        >
          {authentified
            ? (
              <FormulaireNouveau
                onSubmit={handleSubmitFormulaireNew}
                onBack={handleBack}
                loading={loading}
                messageError={messageError}
              />
            )
            : (
              <Authentification
                onSubmit={handleAuthentification}
                loading={loading}
              />
            )}
        </Grid.Row>
      </Grid>
    </>
  );
};

export default Application;
