import _ from 'lodash';
import React, { useState } from 'react';

import PropTypes from 'prop-types';

import {
  Segment,
  Header,
  Form,
  Button,
  Popup,
  Icon,
  Image,
} from '@jvs-group/jvs-mairistem-composants';

import identifiant from '../assets/images/identifiant.png';

/**
 * Authentification.
 *
 * @param {*} props
 */
const Authentification = (props) => {
  const { onSubmit, loading } = props;

  const [
    numeroClient,
    setNumeroClient,
  ] = useState('');

  const [
    codePostal,
    setCodePostal,
  ] = useState('');

  const handleChangeNumero = (e, { value }) => {
    if ((/^\d+$/.test(value) && _.size(value) <= 5) || value === '') {
      setNumeroClient(value);
    }
  };

  const handleChangeCodePostal = (e, { value }) => {
    if ((/^\d+$/.test(value) && _.size(value) <= 5) || value === '') {
      setCodePostal(value);
    }
  };

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit(numeroClient, codePostal);
    }
  };

  return (
    <Segment.Group>
      <Segment basic style={{ paddingBottom: '3rem' }}>
        <Header
          textAlign="center"
          style={{
            alignSelf: 'center',
            color: 'rgb(44, 113, 177)',
            fontSize: 30,
            fontFamily: 'Roboto Condensed, sans-serif',
          }}
        >
          Demande de nouveau certificat
        </Header>
      </Segment>
      <Segment
        basic
        textAlign="center"
        style={{
          width: 275,
          margin: '0 auto',
          padding: 40,
          boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px',
          background: 'white',
        }}
      >
        <Header><b>Authentification</b></Header>
        <Form onSubmit={handleSubmit}>
          <div style={{ display: 'flex' }}>

            <Form.Input
              label="Identifiant"
              value={numeroClient}
              onChange={handleChangeNumero}
              required
              style={{ width: 200 }}
            />
            <Popup
              position="bottom center"
              flowing
              on="hover"
              size="large"
              trigger={(
                <Form.Field style={{ alignItems: 'center', display: 'flex' }}>
                  <Icon name="info circle" link style={{ marginTop: 6, marginLeft: 5 }} />
                </Form.Field>
              )}
              content={
                <Image size="big" src={identifiant} />
              }
            />
          </div>

          <Form.Input
            label="Code postal"
            value={codePostal}
            onChange={handleChangeCodePostal}
            required
            style={{ width: 200 }}
          />
          <Button
            type="submit"
            positive
            loading={loading}
          >
            <Icon name="check" />
            Connexion
          </Button>
        </Form>
      </Segment>
    </Segment.Group>
  );
};

Authentification.propTypes = {
  /** On submit formulaire function. */
  onSubmit: PropTypes.func,
  /** Loading. */
  loading: PropTypes.bool,
};

Authentification.defaultProps = {
  onSubmit: null,
  loading: '',
};

export default Authentification;
