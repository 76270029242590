import _ from 'lodash';
import React, { useState } from 'react';

import PropTypes from 'prop-types';

import {
  Form,
  Segment,
  Header,
  Icon,
  Grid,
  Search,
  Message,
  Checkbox,
  Input,
} from '@jvs-group/jvs-mairistem-composants';

/**
 * Formulaire.
 *
 * @param {*} props
 */
const FormulaireNouveau = (props) => {
  const {
    loading,
    messageError,
  } = props;

  /* eslint-disable array-bracket-newline, array-element-newline */
  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [nomAncien, setNomAncien] = useState('');
  const [prenomAncien, setPrenomAncien] = useState('');
  const [nomBeneficiaire, setNomBeneficiaire] = useState('');
  const [prenomBeneficiaire, setPrenomBeneficiaire] = useState('');
  const [nomAncienTeleservices, setNomAncienTeleservices] = useState('');
  const [prenomAncienTeleservices, setPrenomAncienTeleservices] = useState('');
  const [siret, setSiret] = useState('');
  const [mail, setMail] = useState('');
  const [mailBeneficiaire, setMailBeneficiaire] = useState('');
  const [error, setError] = useState(null);
  const [adressesResults, setAdressesResults] = useState([]);
  const [valueCollectivite, setValueCollectivite] = useState('');
  const [adressesResultsBureau, setAdressesResultsBureau] = useState([]);
  const [valueBureau, setValueBureau] = useState('');
  const [adressesResultsTeleservices, setAdressesResultsTeleservices] = useState([]);
  const [valueTeleservices, setValueTeleservices] = useState('');
  const [teleservices, setTeleservices] = useState(false);
  const [telephone, setTelephone] = useState('');
  const [telephoneRepresentant, setTelephoneRepresentant] = useState('');
  const [telephoneBeneficiaire, setTelephoneBeneficiaire] = useState('');
  /* eslint-enable array-bracket-newline, array-element-newline */

  const handleChangeTelephone = (e, { value }) => {
    const valueReplaced = _.replace(_.replace(_.replace(value, ' ', ''), '.', ''), '-', '');
    if (_.size(valueReplaced) <= 10) {
      setTelephone(value);
    }
  };

  const handleChangeTelephoneRepresentant = (e, { value }) => {
    const valueReplaced = _.replace(_.replace(_.replace(value, ' ', ''), '.', ''), '-', '');
    if (_.size(valueReplaced) <= 10) {
      setTelephoneRepresentant(value);
    }
  };

  const handleChangeTelephoneBeneficiaire = (e, { value }) => {
    const valueReplaced = _.replace(_.replace(_.replace(value, ' ', ''), '.', ''), '-', '');
    if (_.size(valueReplaced) <= 10) {
      setTelephoneBeneficiaire(value);
    }
  };

  const handleChangeNom = (e, { value }) => setNom(value);

  const handleChangePrenom = (e, { value }) => setPrenom(value);

  const handleChangeNomAncien = (e, { value }) => setNomAncien(value);

  const handleChangePrenomAncien = (e, { value }) => setPrenomAncien(value);

  const handleChangeNomBeneficiaire = (e, { value }) => setNomBeneficiaire(value);

  const handleChangePrenomBeneficiaire = (e, { value }) => setPrenomBeneficiaire(value);

  const handleChangeNomAncienTeleservices = (e, { value }) => setNomAncienTeleservices(value);

  const handleChangePrenomAncienTeleservices = (e, { value }) => setPrenomAncienTeleservices(value);

  const handleChangeMail = (e, { value }) => setMail(value);

  const handleChangeMailBeneficiaire = (e, { value }) => setMailBeneficiaire(value);

  const handleBack = () => _.invoke(props, 'onBack');

  const handleChangeSiren = ({ value }) => {
    if (/^\d{0,3}[ ]?\d{0,3}[ ]?\d{0,3}[ ]?\d{0,5}$/.test(value)) {
      setSiret(value);
    }
  };

  const controleCleLuhn = (identifiant) => {
    const identifiantLaPoste = (_.truncate(identifiant, 9) === '356000000');

    let somme = 0;
    let chiffre = 0;
    let pair = false;

    // eslint-disable-next-line no-plusplus
    for (let n = identifiant.length - 1; n >= 0; n--) {
      chiffre = parseInt(identifiant.charAt(n), 10);

      if (pair && !identifiantLaPoste) {
        chiffre *= 2;
        if (chiffre > 9) {
          chiffre -= 9;
        }
      }

      somme += chiffre;
      pair = !pair;
    }

    if (identifiantLaPoste) {
      return (somme % 5) === 0;
    }

    return (somme % 10) === 0;
  };

  const handleSubmit = () => {
    let description = '';

    if (_.isEmpty(nom)) {
      description = "Le nom n'est pas renseigné";
    } else if (_.isEmpty(prenom)) {
      description = "Le prénom n'est pas renseigné";
    } else if (_.isEmpty(nomAncien)) {
      description = "Le nom de l'ancien bénéficiaire du certificat n'est pas renseigné";
    } else if (_.isEmpty(prenomAncien)) {
      description = "Le prénom de l'ancien bénéficiaire du certificat n'est pas renseigné";
    } else if (_.isEmpty(mail)) {
      description = "L'adresse mail pour activer le certificat n'est pas renseignée";
    } else if (_.isEmpty(telephone)) {
      description = "Le numéro de téléphone n'est pas renseigné";
    } else if (_.isEmpty(telephoneRepresentant)) {
      description = "Le numéro de téléphone du représentant légal n'est pas renseigné";
    } else if (_.isEmpty(siret) || _.isNil(siret)) {
      description = "Le SIRET n'est pas renseigné";
    } else if (!controleCleLuhn(_.replace(siret, ' ', ''))) {
      description = 'Le siret est invalide (clé Luhn)';
    } else if (_.isEmpty(valueCollectivite)) {
      description = "L'adresse de la collectivité n'est pas renseignée";
    } else if (_.isEmpty(valueBureau)) {
      description = "L'adresse du bureau de poste pour le retrait du certificat n'est pas renseignée";
    } else if (teleservices && _.isEmpty(valueTeleservices)) {
      description = "L'adresse du bureau de poste pour le retrait du certificat de téléservices n'est pas renseignée";
    } else if (teleservices && _.isEmpty(nomBeneficiaire)) {
      description = "Le nom du bénéficiaire n'est pas renseigné";
    } else if (teleservices && _.isEmpty(prenomBeneficiaire)) {
      description = "Le prénom du bénéficiaire n'est pas renseigné";
    } else if (teleservices && _.isEmpty(mailBeneficiaire)) {
      description = "L'adresse mail du bénéficiaire du certificat n'est pas renseigné";
    } else if (teleservices && _.isEmpty(nomAncienTeleservices)) {
      description = "Le nom de l'ancien bénéficiaire du certificat de téléservices n'est pas renseigné";
    } else if (teleservices && _.isEmpty(prenomAncienTeleservices)) {
      description = "Le prénom de l'ancien bénéficiaire du certificat de téléservices n'est pas renseigné";
    } else if (teleservices && _.isEmpty(telephoneBeneficiaire)) {
      description = "Le numéro de téléphone du bénéficiaire du certificat n'est pas renseigné";
    }

    if (description === '') {
      _.invoke(
        props,
        'onSubmit',
        nom,
        prenom,
        nomAncien,
        prenomAncien,
        nomBeneficiaire,
        prenomBeneficiaire,
        nomAncienTeleservices,
        prenomAncienTeleservices,
        mail,
        mailBeneficiaire,
        siret,
        valueCollectivite,
        valueBureau,
        valueTeleservices,
        telephone,
        telephoneBeneficiaire,
        telephoneRepresentant,
      );
    } else {
      setError(description);
    }
  };

  const handleAdresseChange = (event, { value }) => {
    fetch(`https://api-adresse.data.gouv.fr/search/?q=${value}`)
      .then((response) => response.json())
      .then((json) => {
        setAdressesResults(json.features);
      });
  };

  const handleSearchChange = (e, { value }) => {
    setValueCollectivite(value);

    if (_.size(value) >= 3) {
      handleAdresseChange(null, { value });
    }
  };

  const handleAdresseSelect = (e, { result }) => {
    const { properties } = result || {};
    const { label } = properties || '';

    setValueCollectivite(label);
  };

  const adresseResultRenderer = (data) => {
    const { properties } = data || {};
    const { label } = properties || '';

    return (<Segment style={{ padding: 0 }} basic>{label}</Segment>);
  };

  const handleAdresseChangeBureau = (event, { value }) => {
    fetch(`https://api-adresse.data.gouv.fr/search/?q=${value}`)
      .then((response) => response.json())
      .then((json) => {
        setAdressesResultsBureau(json.features);
      });
  };

  const handleSearchChangeBureau = (e, { value }) => {
    setValueBureau(value);

    if (_.size(value) >= 3) {
      handleAdresseChangeBureau(null, { value });
    }
  };

  const handleAdresseSelectBureau = (e, { result }) => {
    const { properties } = result || {};
    const { label } = properties || '';

    setValueBureau(label);
  };

  const handleChangeCheckbox = (e, { checked }) => {
    setTeleservices(checked);
  };

  const handleAdresseChangeTeleservices = (event, { value }) => {
    fetch(`https://api-adresse.data.gouv.fr/search/?q=${value}`)
      .then((response) => response.json())
      .then((json) => {
        setAdressesResultsTeleservices(json.features);
      });
  };

  const handleSearchChangeTeleservices = (e, { value }) => {
    setValueTeleservices(value);

    if (_.size(value) >= 3) {
      handleAdresseChangeTeleservices(null, { value });
    }
  };

  const handleAdresseSelectTeleservices = (e, { result }) => {
    const { properties } = result || {};
    const { label } = properties || '';

    setValueTeleservices(label);
  };

  return (
    <Segment.Group>
      <Segment basic style={{ padding: 0 }}>
        <Header
          textAlign="center"
          style={{
            alignSelf: 'center',
            color: 'rgb(44, 113, 177)',
            fontSize: 30,
            fontFamily: 'Roboto Condensed, sans-serif',
          }}
        >
          Demande de nouveau certificat exécutif (réservé exclusivement au Maire ou Président)
        </Header>
      </Segment>
      <Segment
        textAlign="left"
        style={{
          width: '70%',
          margin: '40px auto',
          border: 'none',
          padding: 40,
          background: 'white',
          boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px',
        }}
      >
        <Header><b>Formulaire</b></Header>
        <Form onSubmit={handleSubmit}>
          <Form.Group widths="equal">
            <Form.Input
              label="Nom du représentant légal"
              value={nom}
              onChange={handleChangeNom}
              required
            />
            <Form.Input
              label="Prénom du représentant légal"
              value={prenom}
              onChange={handleChangePrenom}
              required
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              label="Nom de l'ancien bénéficiaire du certificat"
              value={nomAncien}
              onChange={handleChangeNomAncien}
              required
            />
            <Form.Input
              label="Prénom de l'ancien bénéficiaire du certificat"
              value={prenomAncien}
              onChange={handleChangePrenomAncien}
              required
            />
          </Form.Group>
          <Form.Group style={{ alignItems: 'flex-end' }}>
            <Form.Input
              width={6}
              type="email"
              label="Adresse mail pour activer le certificat"
              value={mail}
              onChange={handleChangeMail}
              required
            />
            <Form.Input
              width={6}
              label="SIRET"
              value={siret}
              onChange={(e, data) => handleChangeSiren(data, 0)}
              required
            />
            <Form.Input
              width={4}
              label="Téléphone mobile du représentant légal"
              value={telephoneRepresentant}
              onChange={handleChangeTelephoneRepresentant}
              required
            />
          </Form.Group>
          <Form.Group>

            <Form.Field required width={12}>
                        <label>Adresse de la collectivité</label>{/* eslint-disable-line */}
              <Search
                fluid
                minCharacters={3}
                onSearchChange={_.debounce(handleSearchChange, 500, { leading: true })}
                onResultSelect={handleAdresseSelect}
                results={_.isNil(adressesResults) ? [] : adressesResults}
                resultRenderer={adresseResultRenderer}
                value={valueCollectivite}
                noResultsMessage="Aucune adresse correspondante"
                showNoResults={!_.isNil(adressesResults)}
                required
              />
            </Form.Field>
            <Form.Input
              width={4}
              label="Téléphone de la collectivité"
              value={telephone}
              onChange={handleChangeTelephone}
              required
            />
          </Form.Group>
          <Form.Field required>
                        <label>Adresse du bureau de poste ou le bénéficiaire ira chercher son certificat</label>{/* eslint-disable-line */}
            <Search
              fluid
              minCharacters={3}
              onSearchChange={_.debounce(handleSearchChangeBureau, 500, { leading: true })}
              onResultSelect={handleAdresseSelectBureau}
              results={_.isNil(adressesResultsBureau) ? [] : adressesResultsBureau}
              resultRenderer={adresseResultRenderer}
              value={valueBureau}
              noResultsMessage="Aucune adresse correspondante"
              showNoResults={!_.isNil(adressesResultsBureau)}
              required
            />
          </Form.Field>
          <Form.Group widths="equal">
            <Form.Field
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Checkbox
                toggle
                onChange={handleChangeCheckbox}
                label="J'ai également besoin d'un certificat de téléservices"
              />
            </Form.Field>
            <Form.Field style={{ visibility: teleservices ? 'visible' : 'hidden' }} required>
                            <label>Adresse mail du bénéficiaire du certificat</label>{/* eslint-disable-line */}
              <Input
                type="email"
                value={mailBeneficiaire}
                onChange={handleChangeMailBeneficiaire}
                {...teleservices ? { required: true } : {}}
              />
            </Form.Field>
          </Form.Group>
          { teleservices && (
            <>
              <Form.Group widths="equal" style={{ alignItems: 'flex-end' }}>
                <Form.Input
                  label="Nom du bénéficiaire du certificat"
                  value={nomBeneficiaire}
                  onChange={handleChangeNomBeneficiaire}
                  required
                />
                <Form.Input
                  label="Prénom du bénéficiaire du certificat"
                  value={prenomBeneficiaire}
                  onChange={handleChangePrenomBeneficiaire}
                  required
                />
                <Form.Input
                  label="Téléphone mobile du bénéficiaire du certificat"
                  value={telephoneBeneficiaire}
                  onChange={handleChangeTelephoneBeneficiaire}
                  required
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Input
                  label="Nom de l'ancien bénéficiaire du certificat"
                  value={nomAncienTeleservices}
                  onChange={handleChangeNomAncienTeleservices}
                  required
                />
                <Form.Input
                  label="Prénom de l'ancien bénéficiaire du certificat"
                  value={prenomAncienTeleservices}
                  onChange={handleChangePrenomAncienTeleservices}
                  required
                />
              </Form.Group>
              <Form.Field required>
                {/* eslint-disable-next-line */}
                    <label>Adresse du bureau de poste ou le bénéficiaire ira chercher son certificat de téléservices</label>
                <Search
                  fluid
                  minCharacters={3}
                  onSearchChange={
                            _.debounce(handleSearchChangeTeleservices, 500, { leading: true })
                            }
                  onResultSelect={handleAdresseSelectTeleservices}
                  results={_.isNil(adressesResultsTeleservices) ? [] : adressesResultsTeleservices}
                  resultRenderer={adresseResultRenderer}
                  value={valueTeleservices}
                  noResultsMessage="Aucune adresse correspondante"
                  showNoResults={!_.isNil(adressesResultsTeleservices)}
                  required
                />
              </Form.Field>
            </>
          )}
          <Grid>
            <Grid.Row>
              <Grid.Column width={5}>
                <Form.Button
                  type="button"
                  basic
                  floated="left"
                  onClick={handleBack}
                >
                  <Icon name="angle left" />
                  Retour
                </Form.Button>
              </Grid.Column>
              <Grid.Column width={6} />
              <Grid.Column width={5}>
                <Form.Button
                  type="submit"
                  positive
                  loading={loading}
                  floated="right"
                >
                  <Icon name="check" />
                  Envoyer
                </Form.Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
        {(!_.isNil(messageError) || !_.isNil(error)) && (
        <Message error>
          <Message.Header>L&apos;envoi du certificat a échoué.</Message.Header>
          <p>{!_.isNil(error) ? error : messageError}</p>
        </Message>
        )}
      </Segment>
    </Segment.Group>
  );
};

/** Proptypes. */
FormulaireNouveau.propTypes = {
  /** On submit formulaire function. */
  onSubmit: PropTypes.func,

  /** On back formulaire function. */
  onBack: PropTypes.func,

  /** Loading. */
  loading: PropTypes.bool,

  /** Message error. */
  messageError: PropTypes.string,
};

FormulaireNouveau.defaultProps = {
  onSubmit: null,
  onBack: null,
  loading: false,
  messageError: '',
};

export default FormulaireNouveau;
