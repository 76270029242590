import React from 'react';

import { Grid, Image } from '@jvs-group/jvs-mairistem-composants';

import image2 from '../assets/images/2.svg';
import image6 from '../assets/images/6.svg';
import image10 from '../assets/images/10.svg';
import image1Noir from '../assets/images/1-noir.svg';
import image4Noir from '../assets/images/4-noir.svg';

const Background = () => {
  const columnWidth = 200;
  const columnHeight = 200;

  return (
    <Grid style={{
      position: 'fixed',
      top: 0,
      left: 0,
      opacity: '0.7',
      overflow: 'visible',
      height: '100%',
      width: '100%',
      flexDirection: 'column',
      margin: 10,
    }}
    >
      {/* <Grid.Row style={{ height: columnHeight, padding: 0, margin: 0 }}>
        <Grid.Column style={{ width: columnWidth, padding: 0, margin: 0 }} />

      </Grid.Row> */}
      <Grid.Row style={{
        height: columnHeight, padding: 0, margin: 0, flex: 1,
      }}
      >
        <Grid.Column style={{ width: columnWidth, padding: 0, margin: 0 }} />
      </Grid.Row>

      <Grid.Row style={{ height: columnHeight, padding: 0, margin: 0 }}>
        <Grid.Column style={{
          width: columnWidth, padding: 0, margin: 0, flex: 1,
        }}
        />
        <Grid.Column style={{ width: columnWidth, padding: 0, margin: 0 }}>
          <Image src={image2} />
        </Grid.Column>
        <Grid.Column style={{ width: columnWidth, padding: 0, margin: 0 }} />
        <Grid.Column style={{ width: columnWidth, padding: 0, margin: 0 }} />
      </Grid.Row>

      <Grid.Row style={{
        height: columnHeight, padding: 0, margin: 0, flexWrap: 'nowrap',
      }}
      >
        <Grid.Column style={{
          width: columnWidth, padding: 0, margin: 0, flex: 1,
        }}
        />
        <Grid.Column style={{ width: columnWidth, padding: 0, margin: 0 }}>
          <Image src={image1Noir} />
        </Grid.Column>
        <Grid.Column style={{ width: columnWidth, padding: 0, margin: 0 }} />
        <Grid.Column style={{ width: columnWidth, padding: 0, margin: 0 }}>
          <Image src={image6} />

        </Grid.Column>
        <Grid.Column style={{ width: columnWidth, padding: 0, margin: 0 }}>
          <Image src={image10} />
        </Grid.Column>
        <Grid.Column style={{ width: columnWidth, padding: 0, margin: 0 }}>
          <Image src={image4Noir} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default Background;
